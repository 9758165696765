import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ServiceCard } from "./ServiceCard";
import projImg2 from "../assets/img/bale2.png";
import projImg4 from "../assets/img/bale4.jpg";
import projImg5 from "../assets/img/bale5.jpg";
import projImg6 from "../assets/img/bale6.jpg";
import projImg7 from "../assets/img/bale7.jpg";
import projImg8 from "../assets/img/bale8.jpg";
import projImg9 from "../assets/img/bale9.jpg";
import press from "../assets/img/press.jpg";
import press1 from "../assets/img/press1.jpg";
import whiteRagsFiveKgBales from "../assets/img/White 5 kg Bale.png";
import coloredRagsTenKgBales from "../assets/img/Colored Rags 10 Kg Bale.png";
import cartonPackedRags from "../assets/img/Carton Packed Rags.png";

import hotels from "../assets/img/hotels.png";
import hospitals from "../assets/img/hospitals.png";
import automobile from "../assets/img/automobile.png";
import stores from "../assets/img/stores.png";
import nonProfitOrganizations from "../assets/img/nonProfitOrganizations.png";
import textile from "../assets/img/textile.png";


import colorSharp2 from "../assets/img/color-sharp2.png";
import TrackVisibility from 'react-on-screen';


export const Services = () => {
  const industries = [
     {
      title: "Textile",
      description: "Offering a complete range of bailing services to meet all your packaging needs. Whether you're looking for polybags, cartons, or pallet or any custom packaging.",
      imgUrl: textile,
    },
    {
      title: "Hotels",
      description: "Providing premium textile solutions for hotels, including high-quality towels, luxurious bathrobes, bathmats, and a variety of cleaning rags to ensure a pristine environment for guests.",
      imgUrl: hotels,
    },
    {
      title: "Hospitals",
      description: "Supplying hospitals with durable and hygienic textile products, including surgical towels and huck towels, along with medical rags and linens to support a clean and safe healthcare environment.",
      imgUrl: hospitals,
    },
    {
      title: "Automobile & Machinery",
      description: "Offering specialized textile products designed for the automobile and machinery sectors, including heavy-duty rags for efficient cleaning and maintenance.",
      imgUrl: automobile,
    },
    {
      title: "Stores",
      description: "Delivering versatile textile solutions for retail stores, including promotional materials and cleaning rags to maintain a tidy shopping experience.",
      imgUrl: stores,
    },
    {
      title: "Non Profit Organizations",
      description: "Partnering with non-profit organizations by providing sustainable textile products that support their missions and community initiatives.",
      imgUrl: nonProfitOrganizations,
    },
  ];

  const factory = [
    {
      title: "White Rags",
      description: "Available in 100 kg bales for various uses.",
      imgUrl: projImg4,
    },
    {
      title: "Hydraulic Press",
      description: "Efficient hydraulic press for bales of 100 kg.",
      imgUrl: press1,
    },
    {
      title: "Hydraulic Press",
      description: "Efficient hydraulic press for bales of 100 kg.",
      imgUrl: press1,
    },

    {
      title: "Bale of Colored Rags",
      description: "Colored cotton rags bales of 10 kg.",
      imgUrl: coloredRagsTenKgBales,
    },
    {
      title: "Carton Packed Rags",
      description: "Carton packed rags bales of 5 kg.",
      imgUrl: cartonPackedRags,
    },
    {
      title: "White Rags",
      description: "Premium white rags available in 100 kg bales.",
      imgUrl: projImg2,
    },
    {
      title: "Rags",
      description: "Stacked rags available in various sizes.",
      imgUrl: projImg5,
    },
    {
      title: "Rags",
      description: "Stacked rags in different colors and materials.",
      imgUrl: projImg6,
    },
    {
      title: "Rags",
      description: "High-quality rags for industrial use.",
      imgUrl: projImg7,
    },
    {
      title: "Rags",
      description: "Durable stacked rags for cleaning purposes.",
      imgUrl: projImg8,
    },
    {
      title: "Rags",
      description: "Versatile rags for multiple applications.",
      imgUrl: projImg9,
    },
    
    {
      title: "Hydraulic Press",
      description: "Heavy-duty hydraulic press for compacting bales of 100 kg.",
      imgUrl: press,
    },
  ];

  return (
    <section className="service" id="services">
      <Helmet>
        <title>Our Services | Al Hamd Tex - Quality Textile Product Exporters</title>
        <meta name="description" content="Explore our services including manufacturing of industrial gloves and recycled textile products. Quality solutions for all your needs." />
      </Helmet>
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <Tab.Container id="services-tabs" defaultActiveKey="factory">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="factory" aria-label="Factory & Equipment">Factory & Equipment</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="industries" aria-label="Industries We Serve">Industries We Serve</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="factory">
                        <Row>
                          {
                            factory.map((service, index) => {
                              return (
                                <ServiceCard
                                  key={index}
                                  {...service}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="industries">
                        <Row>
                          {
                            industries.map((service, index) => {
                              return (
                                <ServiceCard
                                  key={index}
                                  {...service}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Decorative Background" />
    </section>
  )
}
